@media screen and (max-width:1500px) {
    .track_heading h6{
        font-size: 22px;
    }
    .login-heading br{
        display: none;
    }
    .sidebar-logo img{
        height: 70px;
    }
    .sidebar{
        width: 250px;
    }
    .addvertiser-header, .addvertiser-head, .advertiser-copyright {
        width: calc(100% - 250px);
    }
}

@media screen and (max-width:1366px) {
    .outerSeeall .coll-itemss{
        width: 20%;
    }
    .qr-details a{
        width: 65%;
    }
    .table-haeding{
        padding-bottom: 1rem;
    }
    .track_heading h6{
        font-size: 22px;
    }
    .place-ads, .track-bg, .qr-code{
        padding: 20px;
    }
    .upload-profile {
        height: 280px;
    }
    .OuterdivStep {
        padding: 20px 20px 70px;
        background: #191919;
    }
    
}
@media screen and (max-width:1199px) {
    .plan-heading h4 {
        font-size: 25px;
    }
    .basicplan-item1 {
        padding: 30px 25px;
    }
   
}
@media screen and (max-width:1024px) {
    .outerSeeall .coll-itemss{
    width: 25%;
    margin-bottom: 15px;
}

}
@media screen and (min-width:992.98px) and (max-width:1024px) {
    .header-top .navbar-nav .nav-link {
        padding-right: 10px;
        padding-left: 10px;
        width: max-content;
    }
}

@media (min-width: 992.98px) {
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
        left: -84px !important;
        top: 54px !important;
    }
}

@media screen and (max-width:924.98px) {
    .banner-slider .carousel-caption {
        width: 90%;
        margin: auto;
    }
}

@media screen and (max-width:991.98px) {
    .plan-heading h4 {
        font-size: 22px;
    }

    .plan {
        padding: 100px 0px 0px;
    }

    .plan-amount h2 {
        font-size: 35px;
    }

    .basicplan-item1 {}

    .profile-bg {
        padding: 40px;
    }

    .offcanvas-body ul {
        margin: 0px;
    }

    .addvertiser-head {
        width: 100%;
    }

    .advertiser-mobile-header {
        margin-left: 15px;
    }

    .navbar-toggler {
        background-color: black;
    }

    .desktop-header {
        display: none;
    }

    .mobile-desktop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0px;
    }

    .carousel-control-prev, .carousel-control-next {
        display: none;
    }

    .sidebar {
        display: none;
    }

    .header-menu {
        display: flex;
    }

    .addvertiser-header, .addvertiser-header, .advertiser-copyright {
        width: 100%;
    }

    .addvertiser-header #navbarScroll {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .addvertiser-header #navbarScroll form {
        align-items: center;
        column-gap: 15px;
    }

    .sidebar-mobile-logo svg {
        display: flex;
    }

    .sidebar-mobile-logo svg path {
        fill: white;
    }

    .sidebar-mobile-logo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 15px;
    }

    .sidebar-mobile-logo img {
        width: 100px;
    }

    .sidebar-menu-items a {
        padding: 0px;
    }

    .profile-search {
        display: none;
    }

    .advertiser-mobile-header {
        display: flex;
    }

    .advertiser-mobile-header img {
        width: 80px;
    }

    .advertiser-header .search-bar .nav-link {
        padding-right: 0px;
        padding-left: 10px;
    }

    a.dropdown-toggle.nav-link {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    a.dropdown-toggle.nav-link {
        position: absolute;
        right: -33px;
        top: 5px;
    }

    .dropdown-toggle:empty::after {
        margin-left: -20px;
    }

    .modal-dialog {
        margin: auto;
        display: flex;
        justify-content: center;
        max-width: inherit;
        margin: 0px 30px;
    }

    .playvideo video {
        height: inherit !important;
        width: 100%;
    }

    /* .slider-items img {
        border-radius: 3px;
        height: inherit;
    } */
    .login-heading br {
        display: none;
    }

    .place-ads {
        padding: 15px 15px;
    }
}

@media only screen and (max-width: 768px) {
    .master-container .watch-area .watch-area-inner .player-base {
        padding-top: 0;
    }
    .outerSeeall .coll-itemss{
        width: 33.33%%;
    }
    .watchmovie, .watchmovie-iiner {
        margin-top: 90px;
    }

    .watchmovie-name.mx-15 {
        margin: 0px 0px;
    }

    .videdojss .videojs-big-play-centered, .playvideo video {
        height: calc(100vw * (1080/1920)) !important;
    }

    .playvideo {
        padding-top: 0px;
    }

    .vjs-poster {
        height: calc(100vw * 0.5625);
        background-size: cover;
    }

    .video-js .vjs-big-play-button {
        font-size: 2em;
    }

    .playvideo video {
        object-fit: contain;
    }
}

@media screen and (max-width:768px) {
    .pro-sidebar.md{
        left: 0px;
        position: unset;
    }
}
@media screen and (max-width:767px) {
    .advertiser_heading h4{
        margin-bottom: 0px;
    }
    .about-anylituc{
        padding: 15px 15px;
    }
    .basicplan.streamingtabing .tabs-items{
        flex-wrap: wrap;
    }
    .basicplan.streamingtabing .tabs-items .nav-item{
        width: 33.33%;
    }
    .basicplan.streamingtabing .tabs-items .nav-item .nav-link
    .basicplan .tabs-items{
        width: 100%;
        border-radius: 0px;
    }
    .advertiser_heading{
        flex-wrap: wrap;
        row-gap: 10px;
    }
    .advertiser_heading h4{
        font-size: 22px;
    }
    .addvertiser-header{
        padding: 0px;
    }
    /* .addvertiser-header .container-fluid{
        padding: 0px;
    } */
    .modal-heading h4 {
        font-size: 24px;
        line-height: 35px;
    }
    .title-name{
        padding: 20px 15px;
    }
    .profile{
        margin: 50px 0px;
    }

    .modal-dialog {
        margin: 0px 15px;
    }

    .modal-content {
        padding: 20px 20px 30px;
        margin: 20px 0px;
    }

    .pagination-indicator {
        right: 0px;
        margin: -15px 0 12px 0;
    }

    .playvideo.mx-15 {
        margin: 0px;
    }

    .handle.handleNext {
        right: -15px;

    }

    .innetsliderview {
        height: 450px;
        background-size: cover;
    }

    .download-app {
        column-gap: 20px;
    }

    .banner-slider .carousel-caption {
        width: 98%;
        /* margin: auto; */
    }

    .handle {
        width: 4%;
    }

    .slider {
        padding: 0 0px;
    }

    .switch-button {
        flex-wrap: wrap;
        row-gap: 15px;
    }

    .submit-button {
        padding: 4px 15px !important;
        font-size: 12px !important;
    }

    p.userNmae {
        display: none;
    }
    .filtername {
        width: 35px;
        height: 35px;
        border: 0px;
    }
    .table-haeding{

    }
    .search-icon-dropdwon {
        background: #221F1F;
        padding: 10px;
        position: absolute;
        width: 330px;
        top: 69px;
        right: unset;
        animation-name: slideIn;
        animation-duration: 1s;
        left: unset;
        transform: translate(-80%, 11px);
    }


    .container, .container-sm {
        max-width: 100%;

    }

    .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        padding-left: 12px;
        padding-right: 12px;
    }

    .section-heading {
        padding: 0px 0px;
    }

    .section-heading {
        margin-bottom: 15px;
    }

    .section-heading h5 {
        font-size: 25px;
        margin-bottom: 0px;
    }

    .section-slider .container-fluid {
        padding-right: 12px;
        padding-left: 12px;
    }

    .slider-item.card {
        margin-right: 5px;
    }

    .modal-profile img, .modal-message img, .modal-like img, .modal-share img {
        width: 80px;
    }

    .modal-profile {
        position: absolute;
        top: -11px;
        left: -13px;
    }

    .modal-message {
        position: absolute;
        top: -13px;
        right: -14px;
    }

    .modal-share {
        position: absolute;
        bottom: -17px;
        left: -16px;
    }

    .modal-like {
        position: absolute;
        bottom: -10px;
        right: -14px;
    }

    .modal-servey-image img {
        width: 40%;
    }

    .modal-movie-watch {
        margin: 10px 0px;
    }

    .banner-button, .modal-money .money-button {
        background-color: #FFB91D;
        color: white !important;
        padding: 10px 15px;
        font-size: 14px;
    }

    .modal-money {
        display: flex;
        align-items: center;
        column-gap: 15px;
        margin: auto;
        width: -moz-fit-content;
        width: fit-content;
        flex-wrap: wrap;
        row-gap: 15px;
        text-align: center;
        justify-content: center;
    }

    .modal-servey-image {
        margin-top: 10px;
    }

    .login {
        padding: 30px;
    }

    footer.footer {
        margin-top: 50px;
    }

    .footer-section {
        padding: 40px 0px 20px;
    }

    .basicplan .tabs-items {
        margin: 25px auto 30px;
    }
}

@media screen and (max-width:575px) {
    .verification-radio{
        flex-wrap: wrap;
    }
    .track-plan.login-form .col{
        width: 100%;
        flex: unset;
    }
    .qr-details a{
        width: 100%;
    }
    .qr-details p{
        font-size: 18px;
    }
    .total-prices{
        flex-wrap: wrap;
    }
    .border-border1-1, .border-border1{
        width: 100%;
    }
    .total-prices p{
        margin-bottom: 0px;
    }
    .about-anylituc{
        flex-wrap: wrap;
    }
    .about-anylituc  .titlestremer{
        margin-top: 20px;
    }
    .basicplan.streamingtabing .tabs-items .nav-item button{
        width: 100%!important;
    }
    .basicplan.streamingtabing .tabs-items .nav-item{
        width: 100%;
        flex-basis: unset;
        flex-grow: unset;
    }
    .basicplan.streamingtabing .tabs-items{
        border-radius: 30px;
    }
    .verification-radio .form-check{
        width: 100%;
    }
    .login {
        padding: 25px 18px;

    }.outerSeeall .coll-itemss{
        width: 50%;
    }

    .modal-heading h4 {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
    }

    .banner-button {
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
    }

    .innetsliderview {
        height: 300px;
        background-size: cover;
    }

    .copyright p {
        font-size: 14px;
    }

    .single-content {
        padding: 20px 20px;
    }

    .section-heading {
        padding: 0px 0px;
    }

    .register {
        padding: 40px 15px;
        margin-top: 260px;

    }

    .login-heading p {
        font-size: 15px;
    }

    .slider-items {
        margin: inherit;
    }

    .profile-bg {
        padding: 15px;
    }

    .mobile-logo img {
        width: 100px;
    }

    .mobile-menu img {
        width: 25px;
        height: 25px;
    }

    .header-top .search-bar .nav-link {
        padding-right: 4px;
        padding-left: 4px;
    }

    .user-profile {
        width: 36px;
        height: 36px;
    }

    .search-bar {
        column-gap: 6px;
        margin-left: 5px;
    }

    .search-bar a.dropdown-toggle.nav-link {
        position: absolute;
        right: -24px;
        top: -2px;
    }

    .left-header {
        column-gap: 0px;
    }

    .breadcrumb {
        margin-top: 70px;
    }

    .search-icon-dropdwon {
        top: 44px;

    }

    .banner-img ul li img {
        height: 70px;
    }

    .banner-img ul li {
        padding: 0px 15px;
    }

    .banner-para p {
        font-size: 16px;
    }

    .banner {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 500px;
        height: auto;
        padding: 120px 0px 20px 0px;
    }

    .banner-img {
        margin-bottom: 20px;
        padding-top: 20px;
    }

    .banner-button {
        margin-top: 5px;

    }

    .section-slider {
        margin-top: 40px;
    }

    @keyframes slideIn {
        0% {
            top: 20px;
        }

        100% {
            top: 44px;
        }
    }

    .plan-choose1 {
        flex-wrap: wrap;
        row-gap: 15px;
    }

    .banner-heading h4 {
        font-size: 22px;
    }

    .breadcrumb-banner {
        flex-wrap: wrap;
    }

    .banner-heading {
        width: 100%;
    }

    .modal-close {
        position: absolute;
        top: -15px;
        right: -15px;
    }

    .modal-close img {
        height: 30px;
    }

    .section-heading h5 {
        font-size: 20px;
        margin-bottom: 0px;
    }

    .search-icon-dropdwon {
        transform: translate(-50%, 11px);
    }

    .footer-para br {
        display: none;
    }

    .footer-para {
        margin-top: 15px;
        margin-bottom: 0px;
    }

    .footer-logo img {
        height: 70px;
    }
}

@media screen and (max-width:400px) {

    /* .banner-img ul{
        flex-wrap: wrap;
        row-gap: 30px;
        margin: auto;
    } */
    .banner-img ul li a img {
        width: 50px;
    }

    .banner-content {
        margin: 0px 0px 0px;
    }

    .banner-slider .carousel-caption {
        position: inherit;
        color: #fff;
        text-align: center;
        top: -4% !important;
        left: 0% !important;
        transform: translate(0) !important;
        width: 100% !important;
    }
}