@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Inter', sans-serif;
    background-color: black;
}

ul {
    padding: 0;
}


.form-error {
    color: red;
}

/* .container-fluid{
    padding: 0px 30px !important;
} */

.form-control,
.form-select {
    background: rgb(44 44 44);
    border-radius: 4px;
    border: 1px solid rgb(44 44 44);
    height: 40px;
    font-size: 14px;
    color: white !important;
}

.form-select option {
    background-color: #2c2c2c;
}

select.form-select::placeholder {
    color: white !important;
}

form-select::placeholder {
    color: white !important;
}

.form-control:focus,
.form-select:focus {
    box-shadow: none;
    background: rgba(255, 255, 255, 0.08);
    border: 1px solid #FFB91D;
}

/* .form-check-input:focus{
    border-color: #FFB91D;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(241 185 28 / 3);
} */

.form-check-input:checked {
    background-color: #FFB91D;
    border-color: #FFB91D;
}

.form-floating>.form-control {
    padding: 0.75rem 0.75rem !important;
}

.search-input {
    display: flex;
    align-items: center;
}

.mx-15 {
    margin: 0px 15px;
}

.form-select {
    appearance: auto !important;
}

.authbg {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

/* .slider-img-item:hover img{
    -ms-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  transform: scale(1.5); 
  z-index: 99;
} */

.advertiser-content::-webkit-scrollbar {
    display: none;
}


/* Hide scrollbar for IE, Edge and Firefox */

.advertiser-content {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/*-----------------------haeder--------------------*/

.header-top.scrolled {
    background: black;
}

.header-top {
    position: fixed;
    width: 100%;
    z-index: 2;
    top: 0;
}

.header-top .navbar-brand img {
    width: 120px;
}

.desktop-header {
    padding: 0px 15px;
}

.submit-button {
    background-color: #FFB91D;
    color: white !important;
    padding: 7px 25px !important;
    border-radius: 4px;
    transition: all .5s;
    font-weight: 500 !important;
    font-size: 16px !important;
    border: 1px solid #FFB91D;
}

.right-header {
    justify-content: end;
}

.header-top .navbar-nav .nav-link {
    color: #807E81;
    font-size: 14px;
    font-weight: 400;
    padding-right: px;
    padding-left: 18px;
}

.header-top .navbar-nav .nav {}

.header-top .navbar-nav {
    align-items: center;
}

.header-top .navbar-nav .nav-link.active {
    color: white;
}

.search-bar {
    display: flex;
    align-items: center;
    margin-left: 20px;
    column-gap: 10px;
}

.search-bar {
    margin-right: 10px;
}

.searchicon,
.bellicon {
    font-size: 20px;
}

.searchicon path,
.bellicon path {
    fill: white;
}

.search-bar .dropdown-menu {
    padding: 5px 0px;
}

.search-bar .dropdown-item {
    padding: 7px 15px;
    color: #ffb91d;
    font-size: 14px;
}

.search-bar .dropdown-item:first-child {
    border-bottom: 1px solid #3c3c3c;
}

.search-bar .dropdown-toggle {
    position: absolute;
    right: -38px;
    color: white;
    font-size: 22px;
}

.user-profile {
    border-radius: 100%;
    width: 46px;
    height: 46px;
    background-position: center;
    background-size: cover;
    position: relative;
    margin-right: 10px;
}

.user-profile .dropdown-menu {
right: -15px;
left: auto;
    background: #1a1a1a;
}

.searchicon {
    position: relative
}

.search-icon-dropdwon {
    background: #221F1F;
    padding: 10px;
    position: absolute;
    width: 350px;
    top: 50px;
    right: 0px;
    animation-name: slideIn;
    animation-duration: 1s;
}


@keyframes slideIn {
    0% {
        top: 20px;
    }

    100% {
        top: 69px;
    }
}

.searchiconsize {
    font-size: 20px;
    width: 20px;
}

.search-bar .dropdown-item:hover {
    background-color: #FFB91D;
    color: white;
}

.search-bar .dropdown-toggle {
    color: white !important;
    font-size: 20px !important;
}

.dropdown-divider {
    display: none;
}

.search-input {
    background-color: #FFB91D;
}

.search-input .form-control {
    background-color: black !important;

    border-radius: 0px;
}

.search-input .fa-magnifying-glass {
    width: 56px;
}

.mobile-desktop {
    display: none;
}



.mobile-menu img {
    width: 35px;
    height: 35px;
}

.left-header {
    display: flex;
    align-items: center;
    column-gap: 20px;
}

.right-header .nav-link {
    font-size: 14px;
    font-weight: 400;
    padding-right: 18px;
    padding-left: 18px;
}

.offcanvas {
    background-color: #0A070B;
    box-shadow: 0px -1px 54px rgb(111 111 111 / 35%);
}

.offcanvas-body ul {
    margin: -16px;
}

.offcanvas-body li {
    position: relative;
    border-bottom: 1px solid #ffb00026;
    margin-bottom: 0px;
    margin-top: 0px;
    padding: 2px 0px;
}

.offcanvas-body {
    padding: 0px 15px;
}

.offcanvas-header {
    padding: 15px 15px;
}

.offcanvas-body li a {
    color: white;
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: .5px;
    width: 100%;
    display: block;
    padding: 12px 0px;
}

.offcanvas-body li:hover,
.offcanvas-body li.active {
    background-color: #ffb91d;
}

.offcanvas-close {
    background-color: #ffb91d;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;

}

.offcanvas-close svg {
    width: 18px;
    height: 16px;
}

.offcanvas-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.offcanvas-close svg path {
    fill: white;
}

.offcanvas {
    width: 322px !important;
}

/*--------------------------------banner------------------------------*/
.banner {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 720px;
    position: relative;
    padding-top: 90px;
    display: flex !important;
    align-items: center;
    justify-content: center;

}

/* .banner-content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
} */
.banner-img ul li img {
    height: 125px;
}

.banner-content h6 {
    color: white;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    line-height: 25px;
}

.banner-img {
    text-align: center;
    padding-top: 45px;
}

.banner-img ul {
    display: flex;
    align-items: center;
    justify-content: center;
}

.banner-img ul li {
    list-style: none;
    display: flex;
    padding: 0px 25px;
}

.banner-para {
    text-align: center;
}

.banner-para p {
    color: #e8e8e8;
    font-size: 20px;
    font-weight: 400;
}

.banner-button {
    background-color: #FFB91D;
    color: white !important;
    padding: 10px 50px;
    border-radius: 4px;
    margin: auto;
    display: flex;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.5px;
    margin-top: 45px;
    border: 1px solid #ffb91d;
    transition: all .5s;
    margin-right: auto;
    margin-left: auto;
    width: max-content;
    text-decoration: none;
}

.banner-button:hover,
.submit-button:hover {
    background: transparent !important;
    color: #ffb91d !important;
    border: 1px solid #FFB91D;
    border-color: #FFB91D !important;
}

.banner-slider .carousel-caption {
    position: unset;
    color: #fff;
    /* text-align: center; */
    /* top: 50% !important; */
    /* left: 50% !important; */
    /* transform: translate(-50%, -50%) !important; */
    width: 100%;
}

.banner-slider .carousel-indicators {
    display: none;
}

.banner-slider .carousel-control-prev {
    height: 72px;
    width: 72px;
    background: #333333;
    border-radius: 100%;
    opacity: 1 !important;
    position: absolute;
    display: none;
    top: 50%;
    left: -2%;
}

.banner-slider .carousel-control-prev span {
    width: 20px;
    margin-right: -20px;
}

.banner-slider .carousel-control-next {
    height: 72px;
    width: 72px;
    background: #333333;
    border-radius: 100%;
    display: none;
    opacity: 1 !important;
    position: absolute;
    top: 50%;
    right: -2%;
}

.banner-slider .carousel-control-next span {
    width: 20px;
    margin-left: -20px;
}

.banner-slider {
    overflow: hidden;
}

/*--------------------------------------section-slider-----------------------------------*/
.section-slider {
    margin-top: 40px;
    /* overflow: hidden; */
}

.section-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px 0px 30px;
}

.section-heading {
    margin-bottom: 26px;
}

.section-heading h5 {
    font-size: 32px;
    font-weight: 600;
    color: white;
    margin-bottom: 0px;
}

.section-heading a {
    color: white;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    column-gap: 10px;
    align-items: center;
}

.section-heading a svg path {
    fill: #ffb91d;
}

/* .slider-items {
    margin: 0px -15px 0px 15px;
} */

.slider-items video {
    border-radius: 3px;
    height: 276px;
    /* width: 204px !important; */
    /* background: linear-gradient(360deg, #0A070B 0%, rgba(10, 7, 11, 0) 100%);
    mix-blend-mode: multiply;
    opacity: 0.75; */
}

.section-slider .container-fluid {
    padding-left: 0px;
    padding-right: 0px;
}

.slider-img-item,
.slider-img-item a {
    position: relative;
}

.movie-names::after {
    background: linear-gradient(360deg, #0A070B 0%, rgb(10 7 11 / 2%) 100%);
    mix-blend-mode: multiply;
    opacity: 0.75;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30%;
    content: "";
}

.movie-names {
    padding-bottom: calc(1.32821 * 100%);
    position: relative;
    cursor: pointer;
}

.slider-img-item a {
    /* background: linear-gradient(360deg, #0A070B 0%, rgba(10, 7, 11, 0) 100%);
    mix-blend-mode: multiply;
    opacity: 0.75;*/
}

.movie-names img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    display: inline-block;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

.owl-nav,
.owl-dots {
    display: none;
}

.movie-names h6 {
    position: absolute;
    color: white;
    text-decoration: none;
    font-size: 16px;
    text-transform: capitalize;
    width: 100%;
    z-index: 9;
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 15px;
    padding-top: 15px;
    bottom: 0px;
}

.free-paid-movie {
    top: 0px;
    position: absolute;
    background: #ffb91d;
    border: 1px solid black;
    font-size: 11px;
    right: 0;
    font-weight: 700;
    border-radius: 20px 0px 0px 20px;
    padding: 3px 10px;
    letter-spacing: .5px;
    z-index: 9;
}

/*-----------------------------------footer---------------------------------*/
.footer-section {
    padding: 60px 0px 60px;
}

footer.footer {
    border-top: 2px solid rgba(54, 54, 54, 0.35);
    margin-top: 100px;
}

.footer-para p {
    color: white;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .5px;
    line-height: 27px;
}

.footer-para {
    margin: 30px 0px;
}

.footer-heading h5 {
    color: white;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: .5px;
}

.footer-icon ul {
    padding: 0px;
    display: flex;
}

.footer-icon ul li {
    padding-right: 25px;
    /* padding-left: 15px; */
    padding-top: 15px;
}


.footer-nav-link ul {
    display: block !important;
}

.footer-nav-link ul li {
    list-style: none;
    padding: 20px 0px 0px;
}

.footer-nav-link ul li a {
    color: white;
    font-size: 14px;
    letter-spacing: .5px;
    text-decoration: none;
    font-weight: 400;
}

.footer-nav-link ul li a:hover {
    color: #FFB91D;
}

.download-app {
    padding-top: 20px;
    display: flex;
    column-gap: 30px;
    flex-wrap: wrap;
    row-gap: 15px;
}

.footer-icon ul li a svg path {
    color: white;
}

.footer-icon ul li a svg path:hover {
    color: #ffb91d;
}

.footer-icon ul li a svg {
    width: 25px;
    height: 25px;
}

/*-------------------------------copyright------------------------------------*/
.copyright {
    background-color: #141414;
    padding: 15px 0px;
    text-align: center;
}

.copyright p {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: .5px;
    color: white;
    text-align: center;
    margin-bottom: 0px;
}

.copyright p a {
    color: #FFB91D;
    text-decoration: none;
}



/*-------------------------------------------------login header---------------------------*/
.login-header {
    text-align: center;
    padding-top: 10px;
}

/*-----------------------------------login page-------------------------------*/
.login-form-layout {
    /* height: calc(100vh - 97px); */
    /* display: flex; */
    padding: 50px 0px 20px;
    /* justify-content: center; */
    /* align-items: center; */
    z-index: 2;
    position: relative;
}

.login {
    background-color: #1A1A1A;
    border-radius: 24px;
    padding: 40px;
    margin: auto;


}


.login-heading {
    text-align: center;
}

.login-heading h4 {
    color: white;
    font-size: 32px;
    font-weight: 600;
}

.login-heading p {
    color: #DDDDDD;
    font-size: 16px;

    padding-top: 15px;
}

.login-form {
    margin-top: 40px;
}

.login-form label, .reset-pass label {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
}

.forget-password {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.password a {
    color: #FFB91D;
    font-size: 16px;
    text-transform: capitalize;
    text-decoration: none;
    font-weight: 400;
}

.login-submit-button {
    background-color: #FFB91D;
    color: white;
    padding: 10px 80px;
    border-radius: 4px;
    margin: auto;
    font-size: 16px;
    width: fit-content;
    display: block;

    transition: all .5s;
    border: 1px solid #ffb91d;
}

.login-submit-button:hover {
    background: transparent !important;
    color: #ffb91d !important;
    border: 1px solid #FFB91D;
    border-color: #FFB91D !important;
}

.sign-other-account p {
    color: white;
    font-size: 16px !important;
    font-weight: 400 !important;
    text-align: center;
}

.account-icon ul {
    margin: 0px;
    padding: 0px;
    display: flex;
    justify-content: center;
}

.account-icon ul li {
    margin: 0px 10px;
    list-style: none;
}

.account-icon .gmail {
    background: white;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    display: flex;
    line-height: 28px;
    justify-content: center;
}

.account-icon .facebook {
    background: #395196;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    display: flex;
    line-height: 28px;
    justify-content: center;
}

.account-icon .insta {
    background: linear-gradient(319deg, #5D3FD2 14.56%, #B829A9 44.64%, #BE289D 50.06%, #D0257E 59.8%, #E62256 70.19%, #FFB91D 72.31%, #EE5755 76.35%, #F89554 81.86%, #FFC153 85.44%);
    border-radius: 100%;
    width: 32px;
    height: 32px;
    display: flex;
    line-height: 28px;
    justify-content: center;
}

.sign-up-account p {
    color: white;
    font-size: 16px !important;
    font-weight: 400 !important;
    text-align: center;
}

.sign-up-account p a {
    text-decoration: none;
    color: #ffb91d;
}

/*-------------------------------Register-----------------------------------------*/
.register {
    background-color: #1A1A1A;
    border-radius: 24px;
    padding: 40px;
    margin: auto;

}

.register .form-control,
.register .form-select {
    height: 40px !important;
}

.overfilo-div {
    overflow: hidden;
}

.register .form-select::placeholder {
    font-size: 14px;
}

.register .form-select {
    font-size: 14px;
    line-height: 1;
}

.send-verification p {
    color: white;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: .5px;
}

.register .login-form label {
    color: white !important;
}

.form-check-input[type=radio],
.form-check-input[type=checkbox] {
    height: 20px;
    width: 20px;
    margin-right: 12px;
}

/*---------------------------------verification-code-------------------------------------*/
.verification-code input {
    width: 60px;
    height: 44px;
    margin: auto;
}

.verification-code ul {
    display: flex;
    text-align: center;
}

.verification-code ul li {
    padding: 0px 5px;
    list-style: none;
}

.verification-code input {
    text-align: center;
}

.modal-content {
    background: #0A0909;
    /* box-shadow: 0px -1px 54px rgba(111, 111, 111, 0.35); */
    border-radius: 15px;
    padding: 40px;
    position: relative;

    margin: 70px 0px;
}

.modal-detail {
    text-align: center;
}

.modal-heading h4 {
    color: white;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: .5px;
}

.modal-image p {
    font-weight: 500;
    font-size: 20px;
}

.modal-image img {
    margin: auto;
    width: 214px;
    height: 233px;
    margin: 20px 0px 0px;
}

.modal-close {
    position: absolute;
    top: -25px;
    right: -25px;
}

.modal-close .btn.btn-primary {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
}

.modal-close button.btn.btn-primary:focus {
    box-shadow: none !important;
}

.success-modal .modal-content {
    background-color: #1A1A1A !important;

}

.modal-footer {
    border: none;
    padding: 0;
}

.modal-body {
    padding: 0;
}

.modal-dialog {
    margin: auto;
    display: flex;
    justify-content: center;
}

.modal-servey-image {
    margin-top: 50px;
}

.modal-servey-image video {
    max-width: 100%;
}

.modal-movie-watch {
    margin: 100px 0px;
}

.modal-money .money-button {
    background-color: transparent;
    color: white !important;
    padding: 10px 50px;
    border-radius: 4px;

    display: flex;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.5px;

    border: 1px solid #ffb91d;
    transition: all .5s;
}


.modal-money .money-button:hover {
    background-color: #ffb91d !important;
    border: 1px solid #ffb91d;
    border-color: #ffb91d !important;


}


.save-icon {
    border: 1px solid #ffb91d;
    background-color: white;
    /* width: 50px;
    height: 50px; */

    padding: 10px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-money {
    display: flex;
    align-items: center;
    column-gap: 15px;
    margin: auto;
    width: fit-content;
}

.modal-profile {
    position: absolute;
    top: -18px;
    left: -18px;
    z-index: 4;
}

.modal-message {
    position: absolute;
    top: -22px;
    right: -22px;
    z-index: 4;
}
.modal-profile button.btn.btn-primary, .modal-message button.btn.btn-primary, .modal-money  button.btn.btn-primary, .modal-like button.btn.btn-primary {
    padding: 0px;
}

.modal-like {
    position: absolute;
    bottom: -17px;
    right: -26px;
    z-index: 3;
}

.modal-share {
    position: absolute;
    bottom: -25px;
    left: -25px;
    z-index: 3;
}

/* .modal-body radio:checked ~ .plan-choose1{
    border-color: 1px solid #ffb91d;
} */
.plan-choose1.active {
    border: 1px solid #ffb91d;
}

.plan-days h6,
.plan-price h6 {
    font-weight: 500;
    font-size: 22px;
    color: white;
    margin-bottom: 0px;
    letter-spacing: .5px;
}

.plan-days p {
    font-weight: 400;
    font-size: 14px;
    color: white;
}

.plan-choose1 {
    display: flex;
    column-gap: 15px;
    padding: 15px;
    border-radius: 24px;
    background: rgba(217, 217, 217, 0.17);
    border: 2px solid #2D2C2C;
    border-radius: 10px;
    position: relative;
}

.plan-details {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

/*----------------------------------------playvideo-----------------------------------*/
.playvideo {
    padding-top: 110px;

}

.playvideo video {
    height: 821px !important;
    width: 100%;
    object-fit: fill;
}

.watchmovie-heading {
    margin-top: 20px;
}

.watchmovie-heading h2 {
    color: white;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: .5px;
}
.watchmovie-heading h5 {
    font-size: 16px;
    color: white;
    font-weight: 600;
    letter-spacing: .5px;
    margin-bottom: 5px;
}

.watchmovie-heading p {
    color: #807E81;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: .5px;
    margin-bottom: 0px;
}

/*-----------------------------------Basic Plan--------------------------------*/
.plan {
    padding: 150px 0px 80px;
}

.basicplan .nav-link.active {
    background: #ffb91d;
    border-radius: 100px;
    color: black;
    font-size: 16px;
    font-weight: 600;
}

.basicplan .nav-link {
    color: black;
    font-size: 16px;
    font-weight: 600;
    padding: 14px 50px;
    width: max-content !important;
}

.basicplan .tabs-items {
    background-color: white;
    border-radius: 100px;
    width: fit-content;

    margin: 45px auto 90px
}

.basicplan-item1:hover {
    border: 1px solid #ffb91d;
    background-color: #161616;
}

.basicplan-item1 {
    height: 100%;
    background-color: #2B2B2B;
    border-radius: 24px;
    padding: 45px 30px;
    border: 1px solid #2B2B2B;
}
.plan-image img{
    height: 75px;
}

.plan-heading h4 {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: .5px;
    color: white;
}

.plan-pb-50 {
    padding-bottom: 50px;
}

.plan-image {
    width: fit-content;
    margin: auto;
}

.plan-description p {
    color: #5C5C5C;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: .5px;
    margin-bottom: 0px;
}

.plan-amount h2 {
    color: #F6F6F6;
    font-size: 50px;
    font-weight: 700;
    letter-spacing: .5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.plan-amount h2 span {
    color: #B4B4B4 !important;
    font-size: 30px;
    font-weight: 400;
    padding-left: 8px;
}

.plan-submit-button {
    background-color: #ffb91d;
    padding: 10px 10px;
    width: 100%;
    font-size: 16px;
    margin-top: 20px;
    border-color: #ffb91d;
    transition: all .5s;
}

.plan-submit-button:hover {
    background-color: transparent !important;
    color: #ffb91d !important;
    border: 1px solid #ffb91d !important;
    border-color: #ffb91d;

}

/*---------------------------------profile---------------------------------------*/
.breadcrumb {
    background-image: url('./../img/body-bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 190px;
    margin-top: 110px;
    display: flex;
    align-items: center
}

.banner-heading h4 {
    color: white;
    font-size: 40px;
    font-weight: 500;
    letter-spacing: .5px;

}

.breadcrumb-item ul {
    display: flex;
    margin-bottom: 0px;
}

.breadcrumb-item ul li {
    padding: 0px 5px;
    list-style: none;
    color: white;
    font-size: 14px;
    font-weight: 400;
}

.breadcrumb-item ul li:last-child {
    color: #ffb91d;
}

.breadcrumb-item ul li a {
    text-decoration: none;
    color: #D2D2D2 !important;
}

.breadcrumb-banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/*-----------------------------------profile---------------------------------*/
.profile {
    margin: 100px 0px;
}

.profile-bg {
    padding: 80px;
    background: #1A1A1A;
    box-shadow: 8px 16px 75px rgba(0, 0, 0, 0.16);
    border-radius: 24px;
}

.profile-form.login-form {
    margin-top: 70px;
}

.switch-button {
    display: flex;

}

.switch-button li {
    padding: 0px 30px;
    list-style: none;
}

.switch {
    display: flex;
    column-gap: 15px;
    align-items: center;
    justify-content: center;
}

.switch label {
    margin-bottom: 0px !important;
    font-size: 15px;
    color: white;
    font-weight: 400;
    /* width: max-content; */
}

.switch .form-check-input {
    width: 3em;
    height: 1.6em;
}

.edit-profile {
    text-align: center;
    position: relative;
}

.edit-profile input {
    height: 100%;
    width: 200px;
    position: absolute;
    /* visibility: hidden; */
    opacity: 0;

    z-index: 2;
    left: 50%;
    transform: translate(-50%);
}

.edit-icon {
    color: black;
    background: #ffb91d;
    height: 35px;
    width: 35px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -15px;
    z-index: 1;
    left: 50.8%;
    transform: translate(-50%);
}

.edit-icon svg {
    height: 22px;
    width: 22px;
}

.edit-profile img {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    margin: auto;
}



/*------------------------------------------sidebar----------------------------------------------*/
.header-menu svg path {
    fill: White;
}

.header-menu {
    display: none;
}

.sidebar {
    width: 280px;
    position: fixed;
    left: 0;
    background-color: #101010;
    height: 100vh;
    z-index: 2;
    animation-name: sidebareffect;
    animation-duration: 0.5s;
}

.sidebar-mobile-logo svg {
    display: none;
}

@keyframes sidebareffect {
    0% {
        left: -280px
    }

    100% {
        left: 0
    }
}

.sidebar-logo {
    text-align: center;
    margin: auto;
    display: flex;
    padding: 30px 0px;
    justify-content: center;
}

.sidebar-menu {
    padding: 0px !important;
}

.sidebar-menu-items {
    margin: 0px 0px 10px;
}

.sidebar-menu-items .pro-inner-item {
    list-style-type: none;
    padding: 5px 20px !important;
    height: 45px;
}

.sidebar-menu-items .pro-inner-item.active {
    background: linear-gradient(98.9deg, #FFB91D 25.13%, rgba(255, 185, 29, 0) 123.63%);
}

.sidebar-menu-items .pro-inner-item a {
    display: flex;
    align-items: center;
    width: 100%;
    display: block;
    padding: 0.75rem 0px;
    text-decoration: none;
    color: white !important;
    font-weight: 400;
    font-size: 14px;

}

.dropdownmenu span {
    width: 100%;
    padding: 0.75rem 0px;
    text-decoration: none;
    color: white !important;
    font-weight: 400;
    font-size: 14px;

}

.advertiser-mobile-header {
    display: none;
}

.sidebar-menu-items .pro-inner-item:hover {
    background: linear-gradient(98.9deg, #FFB91D 25.13%, rgba(255, 185, 29, 0) 123.63%);
}

.sidebar-menu-items a svg,
.dropdownmenu svg {
    margin-right: 15px;
}

.sidebar-menu-items.active .pro-inner-item {
    background: linear-gradient(98.9deg, #FFB91D 25.13%, rgba(255, 185, 29, 0) 123.63%);
}

.sidebar-menu-items.active svg path,
.sidebar-menu-items:hover svg path {
    fill: black;
}

.addvertiser-header {
    position: relative;
    width: calc(100% - 280px);
    margin-left: auto;
    padding: 0px 15px;
}

.addvertiser-head .navbar {
    width: 100%;
    background-color: #101010 !important;
}

.addvertiser-head .navbar {
    width: 100%;
    background-color: #101010 !important;
}

.addvertiser-head {
    position: relative;
    width: calc(100% - 280px);
    margin-left: auto;
    padding: 0px 0px;
}

/* .profile-search .form-control{
    margin-right: 0 !important;
    border-radius:8px 0px 0px 8px  !important;
    border:none !important;
    border: none !important;

} */

/* .profile-search.input-group span{
    background: #232323;
    border: none;   
    color: #ADB5BD;
} */
.profile-search {
    position: relative;
    width: 356px;
}

.profile-search svg {
    /* background: #232323; */
    height: 20px;
    width: 20px;
    /* border-radius: 0px 8px 8px 0px; */
    padding: 12px !important;
    position: absolute;
    top: 0px;
    right: 5px;
}

.profile-search .form-control {
    padding-right: 35px !important;
}

.profile-search svg path {
    fill: #ADB5BD;
}

.advertiser-header {
    justify-content: space-between;
}

.advertiser-header .search-bar .nav-link {
    color: #807E81;
    font-size: 14px;
    font-weight: 400;
    padding-right: 18px;
    padding-left: 18px;
    align-items: center;
}

.advertiser-copyright {
    bottom: 0;
    position: fixed;
    right: 0;
    width: calc(100% - 280px);
    padding: 18px 0px;
    text-align: center;
    z-index: 5;

    background-color: #101010;
}

.advertiser-copyright p {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: .5px;
    color: #EEEEEE;
    text-align: center;
    margin-bottom: 0px;
}

.advertiser-copyright p a {
    color: #FFB91D;
    text-decoration: none;
}



/*-----------------------------------------advertiser profile--------------------------------------*/
.advertiser-content {
    /* height: calc(100vh - 132px); */
    overflow: auto;
    padding-bottom: 110px;
}


.advertiser_heading h4 {
    font-weight: 700;
    font-size: 32px;
    color: #ffb91d;
    letter-spacing: .5px;
}

.advertiser_heading a {
    text-decoration: none;
}

.advertiser_heading {
    margin: 20px 0px;

    display: flex;
    justify-content: space-between;
    align-items: center;

}

.advertiser-add-location {
    cursor: pointer;
}

.advertiser-add-location p {
    text-align: end;
    color: #ffb91d;
    padding-top: 18px;
    display: flex;
    column-gap: 15px;
    align-items: center;
    justify-content: end;
}

.advertiser-add-location p span {
    border: 2px solid #ffb91d;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}

.advertiser-add-location p span svg {
    width: 15px;
}

/*----------------------------------------place ads---------------------------------------*/
.filtername {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 45px;
    border: 0px;
    height: 45px;
    background: #FFB91D;
    border-radius: 4px;
}
.rdt_TableRow {
    padding: 10px 0px;
}
.filtername img {
    width: 30px;
    height: 30px;
}

.table-haeding {
    border-bottom: 1px solid #303030;
    display: flex;
    padding-bottom: 2rem !important;
    align-items: center;
    justify-content: space-between;
}

button.filtername.dropdown-toggle.btn.btn-success::after {
    display: none;
}

button.filtername.dropdown-toggle.btn.btn-success:hover {
    background: #FFB91D;
    border-color: #ffb91d;
}

.table-haeding h6 {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 0px;
    letter-spacing: .5px;
    color: white;

}

.place-ads {
    background: #191919;
    border-radius: 12px;
    padding: 30px;
}

.place-ads-table tr th,
.place-ads-table tr td {
    text-align: center;
    padding: 1rem .5rem !important;
}

.place-ads-table tr th {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: .5px;
    color: white;
}

.place-ads-table tr td {
    color: #AEAEAE;
}

.place-ads-table tr {
    border-color: #303030;
}

.video-ad {
    display: flex!important;
    align-items: center;
    column-gap: 15px;
}

.video-ad p {
    margin-bottom: 0px;

    width: max-content;
}

.table-link {
    text-decoration: none;
    color: #AEAEAE;
    font-size: 14px;
    font-weight: 400;
}

.table-link:hover {
    color: #ffb91d;
}

.action-button {
    background: rgba(239, 239, 239, 0.08);
    border-radius: 8px;
    color: white;
    font-size: 11px;
    font-weight: 500;
    border: 1px solid rgba(239, 239, 239, 0.08);
    border-color: rgba(239, 239, 239, 0.08);
    padding: 2px 15px;

}


.action-button:hover {
    border-color: #ffb91d !important;
    border: 1px solid #ffb91d !important;
    color: #ffb91d !important;
    background-color: transparent !important;
}



.active-button {
    background: #166534;
    border-radius: 8px;
    color: white;
    font-size: 11px;
    font-weight: 500;
    min-width: 100px;
    text-decoration: none;
    border: 1px solid #166534;
    padding: 2px 15px;
}

.active-button:hover {
    border-color: #166534 !important;
    border: 1px solid #166534 !important;
    color: white !important;
    background-color: transparent !important;
}

.inactive-button {
    background: #991B1B;
    border-radius: 8px;
    color: white;
    font-size: 11px;
    min-width: 100px;
    font-weight: 500;
    border-color: #991B1B;
    min-width: 100px;
    border: 1px solid #991B1B;
    padding: 2px 15px;
}

.inactive-button:hover {
    border-color: #991B1B !important;
    border: 1px solid #991B1B !important;
    color: white !important;
    background-color: transparent !important;
}

.pending-button {
    background: #FFB91D;
    border-radius: 8px;
    color: white;
    font-size: 11px;
    min-width: 100px;
    font-weight: 500;
    border: 1px solid #FFB91D;
    ;
    padding: 2px 15px;

}

.pending-button:hover {
    border-color: #FFB91D !important;
    border: 1px solid #FFB91D !important;
    color: white !important;
    background-color: #ffb308 !important;
}

.adds p,
.video-ad p {
    margin: 8px 0px 0px 0px;
    font-size: 12px;
    font-weight: 400;
}

/*---------------------------------------track-------------------------------------*/
.track-bg {
    background: #191919;
    border-radius: 12px;
    padding: 40px 50px;
}

.track_heading h6 {
    font-size: 30px;
    font-weight: 400;
    color: white;
    margin-bottom: 20px;
}

.track-plan-item {
    background: #2B2B2B;
    border-radius: 24px;
    padding: 20px;
    text-align: center;
    border: 1px solid;
}

.track-plan-item:hover,
.track-plan-item.active {
    border-color: #ffb91d;
    background: #131313;
}

.track-plan-number,
.track-plan-amount p {
    font-size: 18px;
    font-weight: 600;
    color: white;
    margin-bottom: 10px;

}

.track-plan-number p,
.track-plan-location p {
    margin-bottom: 0px !important;
}

.track-plan-location {
    color: #777777;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}

.planbtn-button {
    color: #ffb91d;
    border: 1px solid #ffb91d;
    border-color: #ffb91d;
    padding: 5px 25px;
    margin-top: 10px;
    background-color: transparent !important;
}

.planbtn-button:hover {
    border-color: #ffb91d !important;
    border: 1px solid #ffb91d;

    color: #ffb91d !important;
}

.user-price p {
    color: #FFB91D;
    background-color: black;
    border-color: #ffb91d;
    border: 1px solid #ffb91d;
    padding: 10px 35px;
    margin: 0px;
    width: fit-content;
}

.qr-code {
    background: #2B2B2B;
    border-radius: 10px;
    padding: 40px;
}

.qr-details {
    text-align: center;
}

.qr-details img {
    margin-bottom: 1.5rem;
}

.qr-details a {
    background: #000000;
    border: 1px solid #FFB91D;
    border-radius: 10px;
    padding: 6px 20px;
    display: block;
    width: 35%;
    margin: auto;
    margin-bottom: 1.5rem;
    text-decoration: none;

    display: flex;
    flex-wrap: wrap;
}

.qr-details a p {
    font-weight: 500;
    color: #ffb91d;
    letter-spacing: .5px;
    font-size: 14px;
    margin-bottom: 0px;
}

.qr-details p {
    color: white;
    font-weight: 500;
    font-size: 22px;
    letter-spacing: .5px;
}

.qr-details img {
    background-color: white;
    border-radius: 10px;
}

/* .qr-details svg path {
    fill: #ffb91d;
} */

.qr-details span {
    font-size: 9px;
    font-weight: 400;
    color: white;
    letter-spacing: .5;
    width: fit-content;
    margin: auto;
}

.share {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;

    width: 100%;
}

.upload-profile {
    position: relative;
    background: #2B2B2B;
    border: 2px dashed #6B6B6B;
    border-radius: 12px;
    padding: 25px 15px;
    text-align: center;
    height: 330px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.upload-profile img.upload_img {
    background: rgb(255 185 29 / 11%);
    mix-blend-mode: normal;
    padding: 12px;
    border-radius: 12px;
}

.upload-profile input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    left: 0;
}

.upload-banner p {
    font-size: 14px;
    color: white;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 0px;
}

.upload-banner img {
    margin-right: 10px;
}

.upload-button {
    background-color: #FFB91D;
    color: white !important;
    padding: 8px 30px !important;
    border-radius: 4px;
    min-width: 150px;
    text-align: center;
    transition: all .5s;
    justify-content: center;
    font-weight: 600 !important;
    font-size: 18px !important;
    border: 1px solid #FFB91D;

    display: flex;
    margin-left: auto;
}

.upload-button:hover {
    background: transparent !important;
    color: #ffb91d !important;
    border: 1px solid #FFB91D;
    border-color: #FFB91D !important;
}

.closeiconall {
    position: absolute;
    right: -10px;
    margin-bottom: 0px;
    top: -10px;
}

.closeiconall img {
    width: 30px;
}

.tittle-heading {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
    text-align: center;
    color: #FFFFFF;
}

.previewComponent .uploadimages {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 41px 17px;
    gap: 10px;
    position: relative;
    background: #2B2B2B;
    border: 2px dashed #6B6B6B;
    border-radius: 12px;
}

.previewComponent .uploadimages input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
}


.custochoose::-webkit-file-upload-button {
    visibility: hidden;
    text-align: left;
}

.custochoose::before {
    content: '';
    /* display: inline-block;
    background: transparent;
    border: 1px solid transparent;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap; */
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    text-align: left;
    visibility: hidden;
    display: none;
    font-size: 10pt;
}

.custochoose:hover::before {
    border-color: black;
}

.custochoose:active::before {
    background: transparent;

}

.upload-appoved {
    background-color: #FFB91D;
    color: white !important;
    padding: 8px 30px !important;
    border-radius: 4px;
    transition: all .5s;
    font-weight: 500 !important;
    font-size: 16px !important;
    border: 1px solid #FFB91D;
    display: flex;

    margin: auto;
}

.countouter .stepcount {
    background: #757575;
    color: #fff;
    width: 50px;
    height: 50px;
    position: relative;
    z-index: 2;
    line-height: 50px;
    text-align: center;
}

.countouter .active.stepcount {
    color: #fff;
    background: #FFB91D;
}

.countouter .active.stepcount:hover {
    color: #fff;
    background: #FFB91D;
}

.countouter [class*="ConnectorContainer-"] {
    top: calc((50px - 1px) / 2);
    left: calc((-50% + 0em) - 0px);
    right: calc((50% + 0em) - 0px);
    position: absolute;
    z-index: 1;
}

.countouter [class*="ConnectorContainer-"] span {
    border-color: #757575 !important;
}

.countouter [class*="LabelContainer-"] {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #757575;
}

.active.stepcount~[class*="LabelContainer-"] {
    color: #FFB91D !important;
}

.countouter [class*="LabelContainer-"] {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #757575;
}


.boxcard {
    background: #0A0909;
    box-shadow: 0px -1px 54px rgba(111, 111, 111, 0.35);
    border-radius: 15px;
    position: relative;
    padding: 50px 30px 25px;
    text-align: center;
}

img.firstImage {
    position: absolute;
    left: 0px;
    top: -10px;
    left: -10px;
}

.senonImages {
    position: absolute;
    top: -10px;
    right: -10px;
}

.thirdImage {
    position: absolute;
    left: -10px;
    bottom: -10px;
}

.forthImage {
    position: absolute;
    right: -10px;
    bottom: -10px;
}


.thumbnailImage {
    width: 100%;
}

.savebutton svg {
    width: 25px;
    height: 25px;

}

.savebutton svg path {

    color: #fff;

}

button.savebutton {
    display: flex;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
    background: transparent;
    margin: auto;
}

button.savebutton span {
    display: block;
    border: 1px solid #ffb91d;
    color: #fff;
    font-size: 14px;
    border-radius: 5px;
    padding: 4px 8px;
}


.OuterdivStep {
    padding: 20px 50px 70px;
    background: #191919;
}


.innerCard {
    background: #000000;
    border: 1px solid #838383;
    border-radius: 10px;

}

.liskwebstis {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.addsurvey {
    justify-content: end;
}

.addsurvey p {
    margin-bottom: 0px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    cursor: pointer;
    color: #FFB000;
}

.innerCard {
    background: #000000;
    border: 1px solid #838383;
    border-radius: 10px;
    padding: 20px 20px;
}

.selct-veido a {
    margin-bottom: 0px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #FFB000;
    text-decoration: none;
}

.selct-veido {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.selct-veido {
    color: #fff;
}

.backBtn {
    background-color: #000;
    color: #fff;
}

.backbtn {
    background: #FFB91D;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
}

. {
    font-weight: 500;
    font-size: 30px;
    color: #FFFFFF;

}

.MuiSlider-track {
    background: #ffb91d !important;
    border-color: #ffb91d !important;
}

.thumbslidedouble {
    height: 8px !important;
    padding-top: 0px;
}

.thumbslidedouble span.MuiSlider-thumb {
    background: #fff;
}

.thumbslidedouble .MuiSlider-rail {
    background: #fff;
}

.thumbslidedouble .MuiSlider-rail {
    background-color: #fff;
    opacity: 1;
}

.sliderlabel {
    text-align: right;
    color: #ffb91d;
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 0px;
}


.targetedaudian h6 {
    font-weight: 500;
    font-size: 30px;
    color: #FFFFFF;
}

.edit_icon {
    background-color: #FFB000;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 30px;
    border: 0px;
    border-radius: 100px;

}

.delete_icon {
    background-color: #ED1D24;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 30px;
    margin-left: 8px;
    border: 0px;
    border-radius: 100px;

}

.delete_icon svg {
    width: 18px;
    height: 18px;
}

.edit_icon svg {
    width: 18px;
    box-sizing: border-box;
}
.icon_survey{
    width: 90px;
}

.surveycard {
    background: #191919;
    border-radius: 10px;
    padding: 20px 20px;

}

.surveycard li {
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid #494949;
}

.surveycard li:last-child {
    margin-bottom: 0px;
    padding-bottom: 0px;
    border-bottom: 0px;
}

.surveycard ul {
    padding: 0px;
    list-style: none;
}

.quetsiondiv {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.quetsiondiv h5{
    width: calc(100% - 90px);
}

.quetsiondiv {
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: #FFFFFF;
}

.surveycard li:last-child {
    border: 0px solid #494949;
}

.surveycard .form-check {
    margin-bottom: 15px;
}

.surveycard label {
    color: #fff;
}

.form-check-input:checked[type=radio] {
    background: #ffb000;
    border: 3px solid #fff;
}

.surveycard .form-check-input {
    background-color: transparent;
    border: 2px solid #fff;
}

.seuveyouter {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 20px;
}

.networkhours {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.uploadnow {
    background: #434343;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
    padding: 8px 30px;
}

.networkhours p {
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    margin-bottom: 0px;
    color: #FFFFFF;
}

.stremerimg img {
    border: 1px solid #5F5F5F;
    border-radius: 15px;
    height: 160px;
}

.stremingpvideo .stremerimg img {
    width: 150px;
}

.streamermovie {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 25px 23px;
    background: #000000;
    column-gap: 25px;
    border-radius: 15px;
}

.titlestremer h4 {
    font-weight: 700;
    font-size: 25px;
    display: flex;
    justify-content: space-between;
    line-height: 32px;
    color: #EBEEF5;
}

.titlestremer {
    width: 100%;
}

.titlestremer p {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #8E95A9;
}

ul.typemovie {
    list-style: none;
    display: flex;
    width: 100%;
    column-gap: 30px;
    flex-wrap: wrap;
}


ul.typemovie li {
    width: 15%;

}

.typemovie li p {
    margin-bottom: 6px;
}

.typemovie li span {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #C3C8D4;
}

.titlestremer h4 .edit_icon {
    line-height: 12px;
}

input[type="date"i] {
    color: #fff;
}

.basicplan.streamingtabing .tabs-items {
    margin: 0px auto 20px;
}

.typemovie li .paid_item {
    background: #FFB91D;
    border-radius: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    padding: 3px 12px;
}

.playadd img {
    width: 100%;
}

.starStrem {
    background: #0A0909;
    border: 1px solid #FFB91D;
    border-radius: 4px;
    font-weight: 400;
    padding: 8px 25px;
    font-size: 16px;
    text-align: center;
    color: #FFB91D;

    min-width: 150px;
}

.starStrem:hover{
    background: #FFB91D;
    border: 1px solid #FFB91D;
    color: #fff;
}
.btn-check:checked+.starStrem, .starStrem.active, .btstarStremn.show, .starStrem:first-child:active, :not(.btn-check)+.starStrem:active{
    background: #FFB91D;
    border: 1px solid #FFB91D;
    color: #fff;
}
.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active{
    box-shadow: none;
}
.playAdd {
    background: #FFB91D;
    border: 1px solid #FFB91D;
    border-radius: 4px;
    font-weight: 400;
    padding: 8px 25px;
    font-size: 16px;
    text-align: center;
    color: #fff;
    min-width: 150px;
}

.startstream {
    display: flex;
    column-gap: 15px;
    flex-wrap: wrap;
    justify-content: center;
}

.notebanner {
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    color: #FFFFFF;
}

.addBannerlink {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.planbannerBox:hover {
    border: 2px solid #FFB91D;
}

.planbannerBox {
    background: rgba(217, 217, 217, 0.17);
    border: 2px solid rgba(217, 217, 217, 0.17);
    border-radius: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

p.bannerprice {
    margin-bottom: 0px;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
}

.login-form .planbannerBox label {
    margin-bottom: 0px;
}

.sidebarnav {
    margin-right: 0px;
    width: 100%;
    left: 0px;
    position: unset;
    min-width: 100%;
}

.sidebarnav .pro-sidebar-inner {
    background: #101010;
}

.upload-profile video,
.upload-profile .imguploing {
    height: 250px;
    width: 100%;
}

.qr-details .form-control {
    height: 100px;
    background: #3C3C3C;
    resize: none;
}

.loaderouter {
    position: fixed;
    width: 100%;
    z-index: 999;
    top: 50%;
    left: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    text-align: center;
    transform: translate(-50%, -50%);
    background: #00000085;
}

.loaderouter img {
    width: 120px;
}

.total-prices .MuiSlider-markLabel {
    font-weight: 500;
    font-size: 16px;
    margin-top: 5px;
    line-height: 20px;
    text-align: right;
    color: #FFB000;
}


.border-border1-1 {
    border: solid thin #2B2B2B;
    padding: 20px 30px;
    width: 70%;
}

.border-border1 {
    border: solid thin #2B2B2B;
    padding: 20px 30px;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #ffff;
}

.total-prices {
    background: #000;
    padding: 25px;
    flex-wrap: wrap;
    display: flex;
    border-radius: 10px;
    justify-content: end;
}


.total-prices h5 {
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    color: #FFB91D;
}

.total-prices p {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #DCDCDC;
}

.targetedaudian .row .col {
    width: 20%;
    flex: unset;
}

.plansTh svg {
    fill: rgb(174, 174, 174);
    width: 18px;
}

.plansTh {
    margin-bottom: 0px;
}

.plansTh button {
    padding: 0px;
}

.MuiTooltip-tooltip {
    font-size: 14px !important;
}

.qr-code-code {
    background: white;
    padding: 25px;
    width: 230px;
    margin-bottom: 1.5rem !important;
    margin: auto;
    border-radius: 10px;
}

.qr-code-code svg {
    width: 100%;
    height: auto;
    max-width: 100%;
    height: 100%;
}

.slider-item-box {
    cursor: pointer;
}

.videdojss .videojs-big-play-centered {
    padding-top: 0px !important;
    height: calc(100vh - 80px - 75px) !important;
    overflow: hidden;


}

.video-js .vjs-tech {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    -o-object-fit: fill;
    object-fit: fill;
    position: absolute;
    background-color: transparent;
}

.videdojss .video-js .vjs-big-play-button {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.errorPage {
    position: fixed;
    width: 100%;
    margin-left: auto;
    padding: 0px 15px;
    height: 100vh;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background: #000;
    z-index: 99;
    top: 0px;
}

.errorPage img {
    width: 60%;
    margin: auto;
}

.errorPage h2 {
    margin-top: 25px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    color: #FFFFFF;

}

.errorPage p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 28px;
    color: #FFFFFF;
}

.modal-position {
    position: relative;
    margin-top: 50px;
    height: 100%;
}

.positionaboutte {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #0a0909;
    top: 0px;
    z-index: 1;
}

.positionaboutte h1 {
    font-family: 'Roboto';
    margin-bottom: 30px;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    color: #FFFFFF;
}

.questionlabel {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    color: #FFFFFF;


}

.no-revenue img {
    width: 40px;
    height: 40px;
}

.no-revenue {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 35px 15px;
    row-gap: 15px;
    column-gap: 15px;
    background: #191919;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
}

.about-anylituc {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    padding: 25px 22px;
    background: #191919;
    border-radius: 15px;
}

.no-revenue h3 {
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 0px;
    text-transform: capitalize;
    color: #F4F0FF;
}

.no-revenue p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-bottom: 0px;
}

.out-chartall {
    background: #191919;
    padding: 20px 20px;
    border-radius: 10px;

}

.out-chartall h4 {
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    color: #FFFFFF;
}

.progresbar-outer h6 {
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 0px;
    line-height: 12px;
    min-width: 110px;
    text-align: right;
    color: #999999;
}

.progresbar-outer p {
    font-weight: 400;
    text-align: right;
    font-size: 15px;
    margin-bottom: 8px;
    line-height: 12px;
    color: #FFFFFF;

}

.progresbar-outer {
    display: flex;
    align-items: end;
    column-gap: 20px;
    justify-content: space-between;
}

.pro-anlytic {
    width: 100%;
}

.bluecolor .progress-bar {
    background-color: #0277BD !important
}

.greencolor .progress-bar {
    background-color: #00AE48 !important
}

.graycolor .progress-bar {
    background-color: #999999 !important
}

.progress {
    height: 12px;
}

.saved-image img {
    height: 300px;
    object-fit: cover;
    width: 100%;
}

.saved-image h5 {
    font-size: 20px;

    color: #fff;
}

.saved-image a {
    text-decoration: none;
}

.saved-image {
    background: #1a1a1a;
    border-radius: 10px;
}

.title-name {
    padding: 25px 25px;
}

.muilabel-input label {
    position: inherit !important;
    text-align: left !important;
    color: #fff !important;
    font-size: 16px !important;
    transform: none !important;
    font-weight: 400 !important;
    margin-bottom: 10px;
    padding-left: 0px !important;
}


.textautomcom .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline {
    background: rgb(44 44 44);
    border-radius: 4px;
    border: 1px solid rgb(44 44 44);
    min-height: 44px;
    padding: 10px;
    color: white !important;
}

.textautomcom .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline:before {
    display: none;
}

.textautomcom .MuiButtonBase-root {
    background-color: rgb(255 185 29);
}

.textautomcom .MuiButtonBase-root .MuiChip-label {
    color: #000;
}

.MuiAutocomplete-endAdornment {
    top: 10px !important
}

.textautomcom .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline::after {
    display: none;
}

.streamcomponent video {
    width: 100%;
}

/* ----------------------------- New Css 15-12-2022------------------------*/
.auth-outer {
    position: relative;
    height: 100vh;
}

.auth-outer-background {
    background-attachment: scroll;
    z-index: -1;
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    transition: background-image 300ms ease-in 200ms;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.movie-names:hover img {
    transform: scale(1);
}

.slider-img-item {
    overflow: hidden;
    border-radius: 5px;
}

/* html{
  height:  100vh
} */
.outBtn {
    display: flex;
    align-items: center;
    column-gap: 12px;
}

.outBtn button.btn {
    width: 30px;
    height: 30px;
    line-height: 30px;
    padding: 0px;
}

button.editt-icon.btn.btn-primary {
    background: #ffb91d;
    border-color: #ffb91d;
}

.single-content {
    box-shadow: 0px 2px 21px 0px rgb(59 53 63 / 12%);
    position: relative;
    z-index: 1;
    color: #fff;
    background: #191919;
    border-radius: 20px;
    padding: 50px 40px;
    overflow: hidden;
}
.single-content p{
    font-size: 14px;
}
.userNmae {
    color: #fff;
    margin-right: 12px;
    margin-bottom: 0px;
}

.genre-width .MuiFormControl-root {
    width: 100%;
    margin: 0px;
}

.genre-width .MuiOutlinedInput-notchedOutline {
    border-color: #FFB91D !important;
    border-width: 0px !important;
}

.genre-width svg.MuiSvgIcon-root {
    color: #FFB91D;
}

.genre-width .MuiSelect-select.MuiSelect-outlined {
    padding: 0.375rem 0px;
}

.swal-modal {
    background-color: #1A1A1A;
    border-radius: 15px;
}

.swal-footer {
    text-align: center;
    margin-top: 20px;
    padding-bottom: 20px;
}

.swal-button {
    padding: 8px 30px;
    background: #FFB91D;
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
}

.swal-button:not([disabled]):hover {
    background-color: #ffb91deb;
}

.rdt_TableRow {
    padding: 15px 0px;
}

.video-ad img {
    width: 70px;
    height: 60px;
    object-fit: cover;
    border: 1px solid #F1F3F9;
}

.swal-icon--success:after, .swal-icon--success:before {
    background: #1a1a1a;
}

.swal-icon--success__line {
    background-color: #ffb91d;
}

.swal-icon--success__hide-corners {
    background-color: #1a1a1a;
}

.swal-icon--success__ring {
    border: 4px solid hsl(41deg 100% 56%);
}

.swal-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 39px;
    color: #FFFFFF;
}

.swal-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;

}

.footer-icon ul {
    list-style: none;
}

.footer-icon ul a {
    color: #fff;
}

.titlh4 {
    font-size: 30px;
    color: #ffff;
}

.out-homejs {
    overflow: hidden;
}

.list {
    width: 100%;
    margin-top: 10px;
}

.list .listTitle {
    color: white;
    font-size: 20px;
    font-weight: 500;
    margin-left: 50px;
}

.list .wrapper {
    position: relative;
}

.list .wrapper .sliderArrow {
    width: 30px;
    height: 100%;
    background-color: #161616;
    color: white;
    position: absolute;
    z-index: 99;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
}

.list .wrapper .sliderArrow.left {
    left: 0;
}

.list .wrapper .sliderArrow.right {
    right: 0;
}

.list .wrapper .container-out {
    margin-left: 30px;
    display: flex;
    margin-top: 10px;
    width: max-content;
    transform: translateX(0px);
    transition: all 1s ease;
}


.listItem {
    width: 190px;
    height: 250px;
    background-color: #000;
    margin-right: 15px;
    /* overflow: hidden; */
    cursor: pointer;
    color: white;
    position: relative;
    transition: all .2s ease-in-out;
    /* transition: transform 0.8s ease-in-out; */
}

.container-out {
    height: 250px;
}

.listItem img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.listItem video {
    width: 100%;
    height: 170px;
    padding: 10px;
    object-fit: cover;
    /* object-fit: cover;
    position: absolute;
    top: 0;
    left: 0; */
}

/* .listItem:hover {
    width: 325px;
    height: 350px;
    position: absolute;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.07);
    box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.07);
    border-radius: 5px;
    z-index: 999; */
/* transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
/* -webkit-animation: scale-up-right 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-right 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both; */
/* transform-origin: center center; */
/* transform: translateX(0px) translateY(0px) scaleX(1) scaleY(1) translateZ(0px); */
/* transform: scale(1.5); */
/* z-index: 999999;
        width: 330px;
        height: 340px;
        position: absolute; */
/* transform: scale(1.5); */
/* } */

.hover-video {
    /* animation: scale-up-bl 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both; */
    animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    z-index: 9999;
    height: max-content;
    width: 20rem;
    position: absolute;
    /* top: -5vh; */
    left: -40%;
    top: -11%;
    transform: translate(-50%, -50%);
    border-radius: 0.3rem;
    box-shadow: rgb(0 0 0 / 75%) 0px 3px 10px;
    background-color: #181818;
    /* -webkit-transition: 0.9s ease-in-out; */
    /* transition: 0.9s ease-in-out; */
}

/* .listItem:hover img {
    height: 170px;
  } */
.listItem:last-child .hover-video {
    left: 0%;
}

.listItem:first-child .hover-video {
    left: 0%;
}

.listItem:hover .itemInfo {
    display: flex;
    flex-direction: column;
    padding: 5px 15px 15px 15px;
}

.listItem:hover .itemInfo .icons {
    display: flex;
    margin-bottom: 10px;
}

.listItem:hover .itemInfo .icons .icon {
    border: 2px solid white;
    padding: 5px;
    border-radius: 50%;
    margin-right: 10px;
    font-size: 30px;
}

.listItem:hover .itemInfo .itemInfoTop {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    color: gray;
}

.listItem:hover .itemInfo .itemInfoTop .limit {
    border: 1px solid gray;
    padding: 1px 3px;
    margin: 0 10px;
}

.listItem:hover .itemInfo .desc {
    font-size: 13px;
    margin-bottom: 10px;
}

.listItem:hover .itemInfo .genre {
    font-size: 14px;
    color: lightgray;
}

.movie-thumbnail {
    position: relative;
}

.movie-thumbnail img {
    width: 100%;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

button {
    background: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    color: #000;
    cursor: pointer;
}

@-webkit-keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(.8);
        transform: scale(.8);
    }

}

@keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(.8);
        transform: scale(.8);
    }
}





/* hero billboard */

/* BILLBOARD REWORK */


.billboard-base {
    position: absolute;
    background-color: #000;
    width: 100%;
    height: 56.25vw;
    z-index: 0;
    top: 0;
}

.billboard-image-wrapper {
    position: relative;

}

.billboard-image-wrapper img {
    width: 100%;
    background-position: center center;
    background-size: cover;
    border: 0;
}

.billboard-vignette-bottom {
    background-image: linear-gradient(to bottom, rgba(20, 20, 20, 0) 0%, rgba(20, 20, 20, 0.15) 15%, rgba(20, 20, 20, 0.35) 29%, rgba(20, 20, 20, 0.58) 44%, #141414 68%, #141414 100%);
    background-size: 100% 100%;
    background-position: 0 top;
    background-repeat: repeat-x;
    background-color: transparent;
    width: 100%;
    height: 14.7vw;
    top: auto;
    bottom: -1px;
    opacity: 0.87;
    z-index: 8;
    position: absolute;
    left: 0;
    right: 0;
}

.billboard-image-wrapper .billboard-maturity-rating {
    position: absolute;
    right: 0;
    bottom: 35%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.billboard-maturity-rating span {
    border: solid 3px#dcdcdc;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-style: none none none solid;
    background-color:
        rgba(51, 51, 51, 0.6);
    font-size: 1.1vw;
    padding: .5vw 3.5vw .5vw .8vw;
    display: flex;
    align-items: center;
    height: 2.4vw;

}

.billboard-content-limits {
    padding-bottom: 35%;
    margin-bottom: 20px;

}

/* billboard information */

.billboard-title {
    min-height: 13.2vw;
    position: relative;
    margin-bottom: 1.2vw;
}

.billboard-title img {
    width: 90%;
    transform-origin: bottom left;
}

.billboard-information {
    position: absolute;
    top: 0;
    bottom: 35%;
    left: 4%;
    width: 36%;
    z-index: 10;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.logo-and-text {
    width: 100%;
    transition: transform 1.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.suplemental {
    font-size: 1.6vw;
    color:
        #fff;
    font-weight: bold;
    transition: color 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.45);
    margin: 1vw 0;
    display: flex;
    align-items: center;
}

.synopsis {
    color:
        #fff;
    font-weight: normal;
    line-height: normal;
    width: 100%;
    font-size: 1.4vw;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.45);
    margin-top: .1vw;
}

.billboard-link {
    display: flex;
    margin-top: 1.5vw;
    white-space: nowrap;
}

.play-link {
    text-decoration: none;
}

.hasLabel {
    border-radius: 0.3rem;
    padding-left: calc(4rem / 2);
    padding-right: calc(4rem / 2);
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    border: 0;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    position: relative;

    margin-right: 1rem;
    margin-bottom: 1rem;
}

.hasLabel span {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 2.4rem;
    word-break: break-word;
    color: #181818;
}


.billboard-vignette {
    transition: opacity 500ms;
    background: linear-gradient(77deg, rgba(0, 0, 0, 0.6) 0%,
            rgba(0, 0, 0, 0) 85%);
    position: absolute;
    top: 0;
    left: 0;
    right: 26.09%;
    bottom: 0;
    opacity: 1;
}

.play-icon {
    margin-right: 10px;
    line-height: 0;
    word-break: break-word;
}

.play-link-secondary {
    background-color: rgba(133, 133, 133, 0.6);
    word-break: break-word;
    font-weight: bold;
}

.play-link-secondary span {
    color: #fff;
}


/* SLIDER */


.sliderBox {
    z-index: 1;
    margin: .5vw 0;
    padding: 0;
    position: relative;

}

.slider-header {
    line-height: 1.3;
    margin: 0;
}

.slider-header a {
    font-size: 1.4vw;
    color: #e5e5e5;
    font-weight: bold;
    margin: 0 4% 0.5em 4%;
    text-decoration: none;
    display: flex;
    align-items: first baseline;

    min-width: 6em;
    cursor: pointer;
}

.slider-header a .see-more {
    margin-left: 1%;
    max-width: 0;
    line-height: 0.8vw;
    opacity: 0;
    font-size: .9vw;
    transition: max-width 1s, opacity 1s, transform 750ms;
    white-space: nowrap;
}

.slider-header a:hover .see-more {
    max-width: 200px;
    transform: translate(1vw, 0);
    opacity: 1;
}

.slider-header a .see-more-chevron {
    display: none;
    transition: transform 750ms;
    margin-left: 4px;
    font-weight: bold;
    font-size: 1vw;
}

.slider-header a:hover .see-more-chevron {
    /* content:'\e658' */
    display: block;
    transform: translate(1vw, 0);
    font-size: .8vw;
    line-height: .8vw;
}

.slider-container {
    position: relative;
    z-index: 3;
    transition: transform 300ms ease 100ms;

}

.slider {
    z-index: 2;
    position: relative;
    margin: 0;
    padding: 0 30px;
}

.slider .sliderMask.showPeek {
    overflow-x: visible;
}


.slider .sliderMask {
    overflow-x: hidden;
    padding-bottom: 1px;
}


.slider .sliderMask .slider-content {
    white-space: nowrap;
}

/* SLIDER ITEM */


.slider .sliderMask .slider-content .slider-item:first-child {
    padding-left: 0;
}



.slider .slider-content .slider-item {
    width: 20%;
    box-sizing: border-box;
    z-index: 1;
    display: inline-block;
    position: relative;
    white-space: normal;
    vertical-align: top;
    padding: 0 2px;
    transition: transform 300ms ease 100ms, height 300ms ease 100ms;
    cursor: pointer;

}

.slider-item .boxart-container {
    width: 100%;
    height: 100%;
}


.boxart {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    padding: 28.125% 0;
}

.item-wrapper video {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    bottom: 0px;
    z-index: 0;
}

.boxart img, .boxart video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.handle {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 20;
    width: 2%;
    text-align: center;
    justify-content: center;
    display: flex;
    color: #fff;
    background: rgba(20, 20, 20, 0.5);
    align-items: center;
    transition: all 0.3s ease-in-out;
}

.handle svg {
    height: 20px;
}

.handle strong {
    /* display: none; */
    height: auto;
    font-size: 2.5vw;
}

.handle.handleNext {
    right: 0;
}

.handle.handlePrev {
    left: 0;
}

.slider:hover .handle {
    opacity: 1;
}

/* .handle:hover strong{
    
  } */

/* ZOOM */

.slider-content {
    transition: transform 400ms ease 200ms;
}

.slider-content:hover .slider-item:hover .show-details {
    opacity: 1;
    animation: chevronSlideDown 0.8s;
}

/* JAWBONE */

.jaw-bone-content {
    background-color: #141414;
    position: absolute;
    left: 0;
    right: 0;
    margin-top: 2px;
    padding: 0;
}

.jaw-bone-open-container {
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.jaw-bone-container {
    background-color: #000;
    position: relative;
    overflow: hidden;
    outline: none;
    height: 42vw;
}

.jaw-bone-container .background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    overflow: hidden;
    transition: opacity 0.3s cubic-bezier(0.5, 0, 0.1, 1) 0s;
}

.jaw-bone-container .jaw-bone {
    padding: 18px 0 12px 4%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
}

.jaw-bone-container .background .nav-shadow {
    height: 114px;
    background: url('https://assets.nflxext.com/en_us/akira/jawBone/nav-shadow.png') no-repeat center 100%;
    background-size: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;
    background-size: 100% 114px;
}

.jaw-bone-container .background .vignette::before {
    content: '';
    position: absolute;
    z-index: 10;
    background-image: linear-gradient(to right,
            #000,
            transparent);
    top: 0;
    bottom: 0;
    left: 100%;
    width: 275px;
}

.jaw-bone-container .background .vignette {
    background:
        #000;
    z-index: 2;
    width: 30%;
    position: absolute;
    top: 0;
    bottom: 0;
}






.jaw-bone-container .jaw-bone-background {
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    width: 70%;
}

.jaw-bone-background .image-rotator {
    background-image: url("https://occ-0-2692-360.1.nflxso.net/dnm/api/v6/E8vDc_W8CLv7-yMQu8KMEC7Rrr8/AAAABbjjL2qGJzMOn4ll2z_7FUhCpubFddwRSN6LWNoJaRPmP0MQOWOkHy93CT7F8XRvWkBzU9LeQtDmholuBEABQTWJPc5k.jpg?r=1dc");
    z-index: 2;
    opacity: 1;
    transition-duration: 750ms;
    transform: translateZ(0);
    background-position: center 10%;


    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background-size: cover;

}

/* JAWBONE INFORMATION */

.jaw-bone-common .metadata {
    position: relative;
    z-index: 2;
    color: #fff;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    font-size: 1.1vw;
    height: 1.1vw;
    min-height: 1.1vw;
    line-height: 1.1vw;

}

.jaw-bone-common {
    margin-top: .3em;
}


.jaw-bone-common .metadata>span {
    font-weight: bold;
    margin-right: 0.5em;
    margin-bottom: 0.3vw;
}

.jaw-bone-common .metadata .imdb a {
    color: #fff;
    font-size: 2.3vw;
    text-decoration: none;

}




.metadata .maturity-rating {
    text-transform: uppercase;
    border: solid 1px rgba(255, 255, 255, 0.4);
    padding: 0 0.4em;
    unicode-bidi: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.metadata .score {
    color: #46D369;
}

.jaw-bone-common .synopsis {
    margin: .8em 0 0 0;
    width: 50rem;
    max-height: calc(5.5rem * 4);
    color: #999;
    overflow: hidden;
    line-height: 1.2;
}

.jaw-bone-common .synopsis::after {
    content: "...";
    position: relative;
    bottom: 0;
    right: 0;
}

.jaw-bone-common .actions {
    margin: .8em 0;
    display: flex;
    align-items: center;
}

.jaw-bone-common .meta-lists {
    margin: 20px 0 0 0;
    width: 45rem;
}

.meta-lists .inline-list {
    font-size: 1.2em;
    color: #828282;
    margin: 2px 0 0 0;
}

.meta-lists .inline-list span {
    color: #828282;
    font-weight: bold;
    margin-right: 5px;
}

.jaw-bone-container .close-button {
    border: 0;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1.3vw;
    padding: 20px 20px 40px 40px;
    z-index: 12;
    cursor: pointer;
    color:
        #fff;

    transform-origin: 60% 35%;
    background: radial-gradient(ellipse at top right, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%);
    background-position-x: 0%;
    background-position-y: 0%;
    background-position: 50% -50%;
}

/* JAWBONE MENU */

.ai-content-area-container .menu {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    font-size: 1vw;
    z-index: 100;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    pointer-events: none;
}

.ai-content-area-container .menu li {
    padding: 1em 1.5em 0;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    color: #ccc;
    min-width: 5%;
    pointer-events: auto;
}

.ai-content-area-container .menu .menu-button {
    margin: 0;
    padding: 0;
    letter-spacing: 1px;
    color: inherit;
    font-weight: bold;
    text-align: center;
    pointer-events: auto;
    text-decoration: none;
    cursor: pointer;
}

.ai-content-area-container .menu li span {
    height: .33em;
    display: block;
    width: 100%;
    margin-top: .66em;
}

.ai-content-area-container .menu li.current span {
    transform: translateY(0);
    background-color: #E50914;
}

.background-app {
    overflow: hidden;
    z-index: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}


/* ITEM MARK */

.mark {
    border: solid 4px #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -4px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    z-index: 4;
}

.mark:before, .mark:after {
    position: absolute;
    width: 0;
    height: 0;
    content: '';
    top: 100%;
    left: 50%;
    margin-left: -13px;
    border-style: solid;
    border-width: 7px 13px 0 13px;
    z-index: 4;
}

.mark:before {
    border-color: rgba(0, 0, 0, 0.15) transparent transparent transparent;
    margin-top: 5px;
}

.mark:after {
    margin-top: 4px;
    border-color: #fff transparent transparent transparent;
}

/* details button */

.show-details {
    position: absolute;
    bottom: 0.1vw;
    opacity: 0;
    border: 0;
    background: transparent;
    outline: none;
    width: 100%;
    transform: translateY(0);
    transition: transform 0.4s, opacity 0.4s;
    transition-delay: 0.2s;
    z-index: 3;
}




.show-details span {
    display: block;
    margin: 0 auto;
    color: white;
    animation-delay: 200ms;
    animation-name: chevronSlideDown;
    animation-duration: 400ms;
    text-align: center;
    cursor: pointer;
}

.show-details span svg:hover {
    color: #E50914;
}

.show-details span svg {
    font-size: 2rem;
}

@keyframes chevronSlideDown {
    0% {
        transform: translateY(-20px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}


.jaw-bone-content.open .jaw-bone-open-container {
    opacity: 1;
}

/* jawbone reworked */


.additional-information {
    position: relative;
    height: 37vw;
    margin-top: -40px;
    margin-top: 2px;
    padding: 0;
    background-color: #000;
    transition: height 0.54s cubic-bezier(0.5, 0, 0.1, 1) 0s, opacity 0.44s cubic-bezier(0.5, 0, 0.1, 1) 0.1s;

}

.ai-background, .ai-background-shadow, .ai-background-image, .ai-content-area {
    position: absolute;
    top: 0;
    bottom: 0;
}

.ai-background {
    left: 0;
    right: 0;

    transition: opacity 0.3s cubic-bezier(0.5, 0, 0.1, 1) 0s;
}

.ai-background-shadow {
    left: 0;
    background: #000;
    width: 30%;
    z-index: 2;
}

.ai-background.dim {
    opacity: 0.2;
}

.ai-background-shadow:before {
    content: '';
    position: absolute;
    z-index: 10;
    background-image: linear-gradient(to right, #000, transparent);
    top: 0;
    bottom: 0;
    left: 100%;
    width: 275px;
}

.ai-background-image {
    right: 0;
    width: 70%;
    height: 100%;
    background-position: center 10%;
    background-size: cover;
    z-index: 1;
}

.ai-background-area {
    left: 0;
    right: 0;
    height: 100%;
    z-index: 3;
}

.ai-background-nav-shadow {
    height: 114px;
    background: url('https://assets.nflxext.com/en_us/akira/jawBone/nav-shadow.png') no-repeat center 100%;
    background-size: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-size: 100% 114px;
}

.ai-close-button {
    color: #fff;
    width: 40px;
    height: 40px;
    background: transparent;
    outline: none;
    border: none;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    font-size: 2vw;
    z-index: 12;

}

.ai-close-button:hover {
    color: #E50914;
}

.ai-content-area {
    left: 0;
    right: 0;
    height: 100%;
    z-index: 3;
}

.ai-content-area-container {
    padding: 18px 0 12px 4%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
}

.ai-content-area-container h3 {
    margin: 0;
    padding: 0;
    font-size: inherit;
}

.ai-content-area-container h3 div {
    display: inline-block;
    position: relative;
    z-index: 1;
    line-height: 1.3;
    margin: 0 0 .2em 0;
    font-weight: bold;
    color: #fff;
    height: 5vw;
    font-size: 3vw;
    width: 50vw;
}


.ai-content-area-container h3 div img {
    height: 7vw;
    transform-origin: 0 0;
}


/* PAGINATION INDICATOR DE UN SLIDER */

.pagination-indicator {
    margin: -24px 0 12px 0;
    padding: 0;
    list-style-type: none;
    position: absolute;
    top: 0;
    right: 30px;
    display: none;
}

.pagination-indicator .active {
    background-color: #aaa;
}

.pagination-indicator li {
    display: inline-block;
    width: 12px;
    height: 2px;
    background-color: #4d4d4d;
    margin-left: 1px;
}

.slider:hover .pagination-indicator {
    display: block;
}


/* SLIDER ITEM OVERLAY ON HOVER */

.item-overlay {
    position: absolute;
    opacity: 0;
    z-index: 2;
    /* background-color:#141414; */
    color: white;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all 0.3s ease-in-out;
}

.item-overlay.open {
    opacity: 1;
}

.item-wrapper {
    position: absolute;
    top: 0;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-end;
    align-items: flex-end;
    padding: 0 2% 2% 2.5%;
    background-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 33%, rgba(0, 0, 0, 0.85) 100%);
    transition: 200ms linear;
    cursor: pointer;
}

.item-overview {
    flex: 0 1 90%;
    max-width: 88%;
    text-align: left;
}

.item-overview-play svg {
    border: 2px solid white;
    border-radius: 50%;
    width: 25px;
    height: 25px;
}

.item-overview-play {
    font-size: 1.6vw;
    padding-left: 2px;
}

.item-overview-title {
    font-size: 1vw;
    line-height: 1.4;
    font-weight: bold;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.7);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 0px
}

.item-overview-metadata {
    font-size: 8px;
    text-shadow: 0 1px 1px rgb(0 0 0 / 70%);
    display: flex;
    flex-wrap: wrap;
    color: lightgray;
    align-items: center;
    margin-bottom: 3px;
    font-weight: 500;
}

.item-overview-metadata span {
    margin-right: 0.5em;
    margin-bottom: 0.3vw;
}

.item-overview-synopsis {
    font-size: 8px;
    color: #e7b510;
    text-shadow: 0 1px 1px rgb(0 0 0 / 70%);
}

.item-overview-synopsis .separator::before {
    color: #646464;
    content: "\2022";
    display: inline-block;
    font-size: 1.4vw;
}

.item-overview-title h6 {
    font-size: 14px;
    margin-bottom: 5px;
}

.item-actions {
    flex: 0 0 10%;
}

.item-action-buttons {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.item-action-buttons div {
    position: relative;
    display: flex;
    align-items: center;
    color: #fff;

}

.item-action-buttons div span {
    pointer-events: auto;
    display: flex;
    width: 1.5em;
    height: 1.5em;
    justify-content: center;
    transition: transform 350ms cubic-bezier(0.86, 0, 0.07, 1);
    align-items: center;
    border: 0.1em solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    padding: 0.3em;
    margin: 0.25em;
    background: rgba(0, 0, 0, 0.5);
}

.item-action-buttons div span svg {
    font-size: .7rem;
}


.item-chevron {
    padding: 0.5vw 0 0 0;
    flex: 0 0 100%;
}

/* rework this */

.slider-content:not(.open) .card:hover {
    transform: scale(1.7, 1.6) !important;
    transition: all 0.3s ease-in-out;
    transition-duration: 500ms;
    z-index: ;

}

.slider-content:not(.open) .poster:hover {
    transform: scale(1.1, 1.2) !important;
    transition: all 0.3s ease-in-out;
    transition-duration: 500ms;

}

.slider-content:not(.open) .slider-item.poster {
    height: 52rem;
}


/* MEDIUM BILLBOARD */


.medium-billboard {
    margin: 3vw 0;
    padding: 0;
    position: relative;

}

.medium-billboard-wrapper {
    position: relative;
    padding: 34.6875vw 0 0 0;
    background-size: cover;
}

.medium-billboard-wrapper div:first-child {
    padding: 0 4%;
}

.medium-billboard-background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-size: cover;
    background-position: top;
    height: 40.078125vw;
    opacity: 0.1;
    pointer-events: none;

    background-image: url("https://occ-0-2692-360.1.nflxso.net/dnm/api/v6/6AYY37jfdO6hpXcMjf9Yu5cnmO0/AAAABSJEkLt3NYvgVYB0hp9WfRMvuHqGqrw2HZn9CDlxZP0CrdmluBhZP5eTcasvQP_WBdja1e_CPEvZhYQACyCAHbYXrAX4.jpg?r=36e");
}

.medium-billboard-background-image:after, .medium-billboard-background-image:before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 5.46875vw;
}

.medium-billboard-background-image:after {
    bottom: 0;
    background-image: linear-gradient(to bottom, rgba(20, 20, 20, 0) 0%, #141414 100%);

}


.medium-billboard-background-image:before {
    top: 0;
    background-image: linear-gradient(to top, rgba(20, 20, 20, 0) 0%, #141414 100%);


}

.md-billboard-video {
    position: absolute;
    top: 3.125vw;
    width: 53.90625vw;
    height: 30.3125vw;
}

.md-billboard-video img {
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    width: 100%;
    z-index: 3;
    opacity: 1;
    pointer-events: none;
    transition: opacity 0.25s ease-in-out;
}

.md-billboard-video-button {
    position: absolute;
    display: flex;
    bottom: 2.734375vw;
    right: 0;
    z-index: 4;
    align-items: center;
    margin-top: 1vw;
}

.md-billboard-video-button button {
    margin-right: 1vw;
}

.md-billboard-maturity {
    border: solid 3px #dcdcdc;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-style: none none none solid;
    background-color: rgba(51, 51, 51, 0.6);
    font-size: 1.1vw;
    padding: 0.5vw 3.5vw 0.5vw 0.8vw;
    display: flex;
    align-items: center;
    height: 2.4vw;

}

.md-billboard-info {
    position: absolute;
    top: 3.125vw;
    left: 60.9375vw;
}

.md-billboard-title {
    width: 27.03125vw;
    font-size: 4vw;
    letter-spacing: -1px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.7);
}

.billboard-additional-information {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 1.7vw;
    margin-top: 0.46875vw;
    width: 27.03125vw;
}

.billboard-synopsis {
    margin-top: 0.46875vw;
    font-size: 1.36vw;
    width: 33.125vw;
}


/* footer */

/* .footer {
    margin-top: auto;
    padding: 2% 4%;
    color: #808080;
    display: flex;
    justify-content: center;
    align-items: center;
  } */

.social-links {
    display: flex;
}

.social-links a {
    margin-right: 15px;
    text-decoration: none;
    cursor: pointer;
    color: #808080;
    font-size: 3.5vw;
}

.social-links a:first-child {
    margin-left: 5px;

}

.social-links a:hover {
    color: #fff;
}

.footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1vw;
}

.copyright {
    font-size: 16px;
    margin-right: 10px;
    width: 100%;
    color: #808080;
}

.react-icon {
    font-size: 3.5vw;
    margin: 0 5px;
    color: #61dafb;
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


.copyright span {
    color: #fff;
    font-size: 13px;
}


/* SIMILAR  */

.menu-similar {
    position: relative;
    padding: 3%;
    height: 85%;

}

.menu-similar-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 85%;


}

.menu-similar-content .similar-item {
    margin-right: 2vw;
    align-self: baseline;
    flex: 1 1;
    overflow: hidden;
    height: 100%;

}

.menu-similar-content .similar-item:last-child {
    margin-right: 0;


}

.similar-item-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 75%;
}

.similar-item .similar-item-metada {
    margin-bottom: 8px;
    font-size: 1.1vw;
    font-weight: bold;

}

.similar-item-metada {
    display: flex;
    justify-content: space-between;
}

.similar-item-metada .release-date {
    font-size: 0.9vw;
    color: #999;
}

.similar-item .similar-item-synopsis {
    color: #999;
    font-size: 0.9vw;

}

.similar-item .similar-item-image {
    margin-bottom: 8px;
}

.similar-item .similar-item-image img {
    width: 100%;
}


/* MENU DETAILS */


.menu-details {
    position: relative;
    padding: 3%;
    height: 85%;
}

.menu-details-content {
    display: flex;
    flex-direction: row;
    height: 85%;

}

.menu-details-item {
    display: flex;
    flex-direction: column;
    font-size: 1vw;
    line-height: 1.5;
    margin-right: 2vw;
}

.details-item-title {
    font-size: 1.2vw;
    color: #999;
    font-weight: bold;
    margin-bottom: 4px;
}


/* GRID */

.grid-container {
    z-index: 1;
    margin: 3vw 0;
    padding: 0;
    position: relative;

}

/* SEARCH */

.search-background {
    background: #141414;
}

.search-container {
    padding: 0 4%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(133px, 1fr));
    grid-template-rows: auto;
    grid-row-gap: 40px;
    opacity: 1;
    transition: all 0.2s ease-in-out;
}


.search-container div {
    padding: 0px 2px;
    height: 200px;
    width: 133px;
    transition: all 0.2s ease-in-out;

}

.search-container div:hover {
    transform: scale(1.2);
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
}

.search-container div img {
    height: 100%;
    width: -moz-available;
}



/* LOADING */


.loading-content {

    height: 200px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}

.loading-circle {
    top: 0;
    width: 200px;
    height: 100px;
    position: absolute;
    border: 3px solid #E50914;
    border-bottom: 0;
    transform-origin: 50% 100%;
    background-color: transparent;
    border-top-left-radius: 110px;
    border-top-right-radius: 110px;
    -ms-transform-origin: 50% 100%;
    -webkit-transform-origin: 50% 100%;
    animation: loading 1.5s linear infinite;
    -webkit-animation: loading 1.5s linear infinite;
}

.loading-name {
    color: #FFF;
    cursor: default;
    position: relative;
    font-weight: bold;

}

/* LOADING ANIMATION */

@keyframes loading {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


/* NO RESULTS */

.not-found {
    padding: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 2rem;

}

.load-more {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    position: relative;
    cursor: pointer;
}

.load-more span {
    border: solid 2px #E50914;
    border-radius: 50%;
    padding: 15px;
}

.load-more span svg {
    font-size: 1.5rem;
    color: #fff;
}

.load-more span:hover {
    border: solid 2px #fff;
}


/* MEDIA QUERIES */

@media screen and (max-width: 499px) {
    .slider .slider-content .slider-item {
        width: 50%;
    }
}

@media screen and (min-width: 500px) and (max-width: 799px) {
    .slider .slider-content .slider-item {
        width: 33.333333%;
    }
}

@media screen and (min-width: 800px) and (max-width: 1099px) {
    .slider .slider-content .slider-item {
        width: 25%;
    }
}

@media screen and (min-width: 1100px) and (max-width: 1399px) {
    .slider .slider-content .slider-item {
        width: 20%;
    }
}

@media screen and (min-width: 1400px) {
    .slider .slider-content .slider-item {
        width: 16.66666667%;
    }
}



@media screen and (max-width: 1099px) {
    .header-list .header-list-item:not(.menu-trigger) {
        display: none;
    }

    .menu-trigger {
        display: block;
    }
}




/* SUBMENU */

.navigation-menu {
    display: flex;
    align-items: center;
    font-weight: bold;
    height: 100%;
}

.menu-trigger:hover .submenu {
    opacity: 1;
    visibility: visible;
}

.navigation-menu:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #fff transparent transparent transparent;
    margin-left: 5px;
}

.submenu {
    opacity: 0;
    cursor: default;
    visibility: hidden;
    margin-left: -90px;
    box-sizing: border-box;
    top: 71px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.9);
    color: white;
    font-size: 13px;
    line-height: 21px;
    border: solid 1px rgba(255, 255, 255, 0.15);
    transition-duration: 150ms;
}

.submenu .arrow {
    position: absolute;
    top: -16px;
    left: 50%;
    border-width: 7px;
    margin-left: -7px;
    border-color: transparent transparent #e5e5e5;
    border-style: solid;
    height: 0;
    width: 0;
}

.submenu .topbar {
    position: absolute;
    top: -2px;
    left: 0;
    right: 0;
    height: 2px;
    background: #e5e5e5;
}

.submenu ul, .submenu ul li {
    padding: 0;
    list-style-type: none;
    margin: 0;
}

.submenu ul {
    cursor: default;
    height: auto;
}

.submenu ul li a {
    width: 260px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: #b3b3b3;
    transition: background-color 400ms;
}

.submenu ul li:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.slider-item.card {
    padding: 0px;
    background-color: transparent;
    margin-right: 10px;
}

.header-top .search-bar .nav-link {
    padding-right: 8px;
    padding-left: 8px;
}

.time-picker .MuiFormLabel-root {
    display: none;
}

.time-picker input, .time-picker .MuiInputBase-formControl {
    width: 100%;
    background: rgb(44 44 44);
    border-radius: 4px;
    border: 0px solid rgb(44 44 44);
    height: 40px;
    font-size: 14px;
    padding-left: 0.75rem;
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 0px;
    padding-right: 0.75rem;
    color: white !important;
}

.time-picker input {
    width: 100%;
    background: rgb(44 44 44);
    border-radius: 4px;
    border: 0px solid rgb(44 44 44);
    height: 40px;
    font-size: 14px;
    padding-left: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 0px;
    padding-right: 0px;
    color: white !important;
}

.time-picker svg path {
    color: #fff;
}

.time-picker {
    width: 100%;
    background: rgb(44 44 44);
    border-radius: 4px;
    font-size: 14px;
    color: white !important;
}

.time-picker .MuiOutlinedInput-notchedOutline {
    border-color: rgb(44 44 44) !important;
    border-width: 0px !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    border-radius: 4px;
    margin-right: 2px;
    opacity: 1;
    filter: invert(0.8);
}
.innetsliderview {
    height: 720px;
    background-size: cover;
}
.plan .nav-justified .nav-item,  .plan .nav-justified>.nav-link{
    min-width: 180px;
}
 nav.rdt_Pagination {
color: #e7b619;
}
.transaction-inner .rdt_TableHeadRow{
    background-color: rgb(230 178 9)!important;
    color: #fff;
    font-size: 14px;
}
.outerSeeall .slider .slider-content .slider-item, .outerSeeall .coll-itemss{
    width: 100%;
}
.outerSeeall .slider-item:hover .item-overlay {
    opacity: 1;
}
.outerSeeall .coll-itemss{
    width: 20%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
    margin-bottom: 24px;
}
.outerSeeall .slider-content {
    display: flex;
    flex-wrap: wrap;
       --bs-gutter-x: 12px;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
}

.outerSeeall .slider-content:not(.open) .card:hover {
    z-index: 9;

}


.outerSeeall .slider-item.card{
    margin-right: 0px;
}

    
    .search-bar .title-dropdown.dropdown-toggle {
        position: unset;
        right: 0;
        color: white;
        font-size: 22px;
        background: transparent;
        border: 0px;
        padding: 0px;
    }


    .notificataion-drop{
        min-width: 250px;
        padding: 12px 10px!important;
    }
   .h6-title-noti {
    margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .h6-title-noti h6{
        margin-bottom: 0px;
            font-size: 15px;
            font-weight: bold;
        
    }
    .h6-title-noti a{
        text-decoration: none;
        font-size: 13px;
    }
    .h6-title-noti a:hover{
        color: #ffb91d;
    }
    .firstnotification {
        border-bottom: 1px solid #e8e8e8;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }
    .firstnotification a{
        padding: 5px 10px!important;
    }
    .firstnotification:last-child{
        margin-bottom: 0px;
        padding-bottom: 0px;
        border-bottom: 0px;
    }
    .helpoutline path{
        color: #fff;

    }
    
    .helpoutline svg{
        width: 20px;
    }
    .modal-backdrop{
        --bs-backdrop-bg: #5b5b5b33;
    }
    .modla-add-staff .modal-content{
        padding: 20px;
         
    }
    .modla-add-staff .modal-content  .titlh4 {
        font-size: 20px;
    }
    .modla-add-staff .modal-content  .modal-header {
        font-size: 20px;
    }
    .out-chartall.bbar-chart canvas{
        width: 100%!important;
    }
    
    /* width */
    .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout::-webkit-scrollbar-track {
    background: #fff; 
  }
   
  /* Handle */
  .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout::-webkit-scrollbar-thumb {
    background: #ffb91d; 
  }
  
  /* Handle on hover */
  .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout::-webkit-scrollbar-thumb:hover {
    background: #ffb91d; 
  }
 .modal-servey-image img{
    margin: auto;
    width: 100%;
    object-fit: cover;
    height: 400px;
    margin: 20px 0px 0px;
}
 .modal-servey-image video {
    margin: auto;
    width: 100%;
    object-fit: fill;
    height: 50vh;
    margin: 20px 0px 0px;
}


.place-ads-table .dropdown .dotsss-img{
    width: 25px;
    height: 25px;

}
.video-aad img {
    width: 70px;
    height: 60px;
    object-fit: cover;
    border: 1px solid #F1F3F9;
}

.video-aad {
    display: flex!important;
    align-items: center;
    column-gap: 15px;
}
.video-aad p{
    margin-bottom: 0px;
}
button.edit_icon, button.delete_icon{
    padding: 0px;
}
button.btn.btn-primary {
    background: #ffb91d;
    border-color: black;
}
.liskwebstis {

}